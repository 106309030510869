
//==========  Import modules  ==========//

// import our global modules
@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

// import our local modules
@import '../modules/local/typography/_index';


//==========  Begin styles  ==========//

// Helper class
.hidden {
	display: none;
}


// Reset
.cms-toolbar {
	@extend .hidden;
}


.mobile-ui {
	@extend .hidden;
}


// Print styles
* {
	color: $color-black;
	background: none;
}

.wrapper {
	padding-bottom: 0 !important;
	padding: 2rem;
}

.header {

	.menu-toggle,
	.nav {
		@extend .hidden;
	}

	.inner {

		* {
			@extend .hidden;
		}

		.company-logo-link,
		.company-logo {
			display: block;
		}

		.company-logo {
			margin-bottom: 3rem;
		}
	}
}

.hero {

	.inner {

		* {
			@extend .hidden;
		}

		.profile-image-wrapper,
		.profile-image {
			display: block;
		}

		.profile-image-wrapper {
			margin-bottom: 3rem;
		}
	}
}

.column {

	&.right,
	&.left {
		@extend .hidden;
	}
}

.pre-footer {
	@extend .hidden;
}

.search-container {
	@extend .hidden;
}

.social-icons,
.footer-links,
iframe,
.back-to-top {
	@extend .hidden;
}

.cookies {
	@extend .hidden;
}

#ccc-notify {
	display: none!important;
}
